import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const ThanksPage = () => (
  <Layout>
    <SiteMetadata title="Contact" description="Contact Tara Segda" />
    <div className="bg-gray-100 py-12 lg:py-16 min-h-1/4">
      <section className="text-gray-700 body-font">
        <h1 className="p-6 text-xl text-center font-bold text-gray-900 sm:text-4xl">
          Thank you for your message!
        </h1>
        <h3 className="text-xl text-center font-bold text-gray-900 sm:text-2xl">I will get back to you shortly</h3>
      </section>
    </div>
  </Layout>
)

export default ThanksPage
